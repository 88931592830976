import React, { FC } from 'react';
import { Container } from 'react-bootstrap';

export interface IPersonProps {
  imageUrl?: string;
  name?: string;
  blurb?: string;
  className?: string;
}

const Person : FC<IPersonProps> = (props: IPersonProps) => {

  const _renderImage = (): React.ReactNode => <img src={props.imageUrl} alt={props.name} />;
  const _renderBlurb = (): React.ReactNode => <div className="person-blurb">{props.blurb}</div>;

  return (
    <div className={`${props.className} person`}>
      <Container>
        <div className="person-content-container">
          <h4 className="person-name">{props.name}</h4>
            {_renderImage()}
            {_renderBlurb()}
        </div>
      </Container>
    </div>
  );
};

export default Person;

