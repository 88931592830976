
import { Container } from 'react-bootstrap';
import strings from '../strings';

export default function Http404Page() {
  return (
    <section>
      <Container>
        <h1>{strings.http404Title}</h1>
        <p>{strings.http404Text}</p>
      </Container>
    </section>
  );
}