import { FC } from 'react';
import { Nav, NavbarBrand, Container, Navbar, NavDropdown } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import strings from '../strings';
import logo from '../res/mcnet-logo-black.png';
import { Globe } from 'react-bootstrap-icons';
import '../common.scss';
import Language from '../Language';

export const HEADER_HEIGHT : number = 59;

export interface IHeaderProps {
  language: Language;
  setLanguage?: (language: Language) => void;
}

const Header : FC<IHeaderProps> = (props: IHeaderProps) => {

  const _setLanguageToEnglish = (): void => {
    props.setLanguage?.('en');
  };
  const _setLanguageToSpanish = (): void => {
    props.setLanguage?.('es');
  };
  const _setLanguageToRussian = (): void => {
    props.setLanguage?.('ru');
  };

  return (
    <header>
      <Navbar expand="lg">
        <Container>
          <NavbarBrand>
            <HashLink 
              className='nav-link' 
              to='/#'>
              <img 
                src={logo} 
                style={{height: '2rem'}} 
                alt={strings.headerLogoAltText}
              />
              <h1 className="page-title">{strings.orgShortName}</h1>
            </HashLink>
          </NavbarBrand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <HashLink 
                className='nav-link' 
                to='/#program-title-anchor'>
                {strings.navProgram}
              </HashLink>
              <HashLink 
                className='nav-link'
                to='/international-team#'>
                {strings.navInternationalTeam}
              </HashLink>
              <HashLink 
                className='nav-link'
                to='/approved-mentors#'>
                {strings.navApprovedMentors}
              </HashLink>
            </Nav>
            <Nav>
              <NavDropdown 
                title={<>
                  <Globe />
                  &nbsp;
                  {props.language}
                </>}>
                <NavDropdown.Item onClick={_setLanguageToEnglish}>English</NavDropdown.Item>
                <NavDropdown.Item onClick={_setLanguageToSpanish}>Español</NavDropdown.Item>
                <NavDropdown.Item onClick={_setLanguageToRussian}>Русский</NavDropdown.Item>
              </NavDropdown>
              <a
                className='nav-link'
                href='https://climbintercultural.ca/donate?external=1'
                target='_blank'
                rel='noreferrer'>
                {strings.navDonate}
              </a>
              <a
                className='nav-link link-to-login'
                href='https://app.mcnetcli.org/Login'>
                {strings.navLogin}
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;