import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    // HTTP 404 Page
    http404Title: 'Not Found',
    http404Text: "Sorry, the page you're looking for doesn't appear to exist.",
    // Organization
    orgShortName: 'MCNet CLI',
    orgName: 'MCNet Christian Leadership Institute',
    // Header
    headerLogoAltText: 'MCNet CLI Logo',
    // Nav bar
    navProgram: 'The Program',
    navInternationalTeam: 'The International Team',
    navApprovedMentors: 'The Approved Mentors',
    navDonate: 'Donate',
    navLogin: 'Login',
    // Footer
    footerCopyRight: '©{0} CLIMB Intercultural Society',
    // Welcome
    welcomeTitle: 'Welcome to the Christian Leadership Institute',
    welcomeText: 'The MCNet Christian Leadership Institute is a global Competency Based Mentorship for those called to full time Christian Ministry. It is an initiative of International Christian Ministries and CLIMB and based upon the Coaching thru Intentional Relationships program. Completion provides a Certificate in Christian Leadership and an Advanced Certificate in Christian Ministry. This is an innovative new approach of formalized ministerial training that goes beyond just focusing on time based or credential based learning. The Ministry Coaching Network (MCNet) is a global community of leaders who share the values of servant leadership and mentoring as a foundation for reproducing healthy churches. ',
    // Links
    linkSeeTheTeam: 'See the Team',
    linkSeeTheProgram: 'See the Program',
    linkSeeTheOrientationManual: 'See the Orientation Manual',
    // Program 
    programTitle: 'The Program',
    // Competency Based Leadership Training
    competencyTitle: 'Competency Based Leadership Training',
    competencyParagraph1: 'Competency based learning is a training model that focuses on outcomes that translate into effectiveness in the professional disciplines. This learning model is commonly used in vocational training, technical training, medical training or any areas where the focus is on skills used to perform specific tasks. Competency based learning can be contrasted with both time based and credential based learning.',
    competencyParagraph2: 'Most academic models stress measuring learning using the categories of time spent, credits earned and tests based on the recall of knowledge. Competency based learning is a learning system based on a predetermined set of competencies, assessment of the learner based on those competencies, development of a learning track unique to each learner, evaluation of the learner’s progress and testing of the learner in an actual working context.',
    competencyParagraph3: 'There are several clear advantages of a competency based learning model:',
    competencySubtitle1: 'Competency based learning recognizes the leader’s skills and experiences',
    competencySubparagraph1: 'The starting point in the competency based model is not a predetermined program that a student must complete to receive a degree or other recognition. The starting point in this model is the assessment of the candidate’s character, knowledge, skills and experience for the purpose of determining what the candidate is missing so that a development process can be specifically designed for the candidate.',
    competencySubtitle2: 'Competency based learning is focused on what the leader needs',
    competencySubparagraph2: 'In this sense the time element of the process is not a predetermined number of semesters or years of education. The time spent in this process depends on the level of the candidate’s development as a leader. More mature or experienced candidates will take less time to complete the program because they can focus on what they need and not have to give time to areas and subjects where they already demonstrate proficiency. Being able to focus on what is needed also has the advantage of allowing the candidate focus on areas where increased effectiveness is necessary for their leadership development rather than repeating things where they already demonstrate having a high degree of expertise.',
    competencySubtitle3: 'Competency based learning focuses on learning while doing, rather than a mere academic emphasis',
    competencySubparagraph3: 'It is also best if a candidate is involved in ministry at a leadership level that is appropriate to their current level of proficiency in character, knowledge, skills and experience. It is better for them to learn “on the job” in real situations, being mentored, and getting actual experience as opposed to hypothetical learning in a contrived learning environment. A competency based focus along with being involved in ministry leadership allows candidates to contribute in meaningful ways as they are going through the training process. It also communicates the idea that continuous training and learning are an integral part of ministry leadership. One of the current realities of ministry is the fact that more leaders are dropping out than are being deployed. Competency based leadership training helps to address this issue by having leaders involved in ministry during the training process while having the benefit of experienced mentors to help guide them along the way.',
    competencySubtitle4: 'Competency based learning aligns preparation with experience and proficiency',
    competencySubparagraph4: 'Competency based training also has the advantage of deploying leaders who have been carefully observed and evaluated on a very personal level as opposed to a typical graduate of a theological educational program that is released into ministry upon graduation, but does not necessarily have the appropriate experience that prepares them for the task. A serious barrier to effective, sustainable leadership is the imbalance that often occurs between a leader’s preparation and experience. Some have ample education and training, but lack experience. Others are thrust into positions for which they have little training or educational preparation. A competency based approach emphasizes a balance between preparation and proficiency compared to their level of responsibility. Keeping this balance in mind and applying the practices learned in a competency based system will allow a leader to be effective as well as durable.',
    // Cohort Mentorship
    cohortMentorshipTitle: 'Cohort Mentorship',
    cohortMentorshipParagraph1: 'For more details on the Mentorship Component see {0}.',
    cohortMentorshipOrientationManual: 'Orientation Manual',
    cohortMentorshipParagraph2: 'At the center of this competency based program for training ministers is the mentoring relationship. As this section of the orientation explains, this is not a new concept, but the process by which Jesus trained his disciples, the first leaders of the emerging church.',
    cohortMentorshipParagraph3: 'Mentoring is a core value of the Ministry Coaching Network and central to all of our leadership training. However, many have not had a mentor and do not know how to pass on leadership in this way. Consequently, the focus becomes transferring knowledge, rather than life transformation. Mentoring is a learned life skill and this section is a brief overview of mentoring and provides the skills necessary to be an effective mentor.',
    cohortMentorshipParagraph4: 'Generally, mentoring is coming alongside of someone else and help them reach their goals; helping someone reach their full potential. In the context of this program, it is helping a ministry candidate increase their proficiency in various competencies related to effective service in the body of Christ. It balances Character, Knowledge and Skills.',
    cohortMentorshipParagraph5: 'Mentoring intelligence refers to the abilities of both the individual and organization in encouraging and fostering mentoring types of relationships. Even experienced leaders may struggle in mentoring others due to the fact that they are not aware of the basic skills necessary in order to mentor another person. They may not even be aware that these are learned skills.',
    cohortMentorshipSubtitle1: 'The Foundation',
    cohortMentorshipSubparagraph1: 'Servant leadership, as described in the Bible, is the only type of leadership that reproduces life in others. It is only genuine life that can reproduce other life. If we do not have this healthy DNA, we will reproduce stillborn leaders – or leaders who cannot reproduce others. This results in producing clones or those dependent upon us.',
    cohortMentorshipSubtitle2: 'The Focus',
    cohortMentorshipSubparagraph2a: 'It is based upon a relationship and not a positional or hierarchical approach to leading others.  It focuses on releasing others to their life calling and not controlling them to fit ours. It is based on accountabiliy and vulnerability and not an independent attitude. It is authentic leadership that has a Kingdom focus.',
    cohortMentorshipSubparagraph2b: 'It is NOT limited to spiritual discipleship, though the process is similar to discipleship. Its focus is more directed towards leadership development of the whole person. It is also not the unhealthy approach of spiritual fathering where one “father” tries to control others as small children. The mentor also does not need to be perfect or, a professional counselor or an elder partriarch.',
    cohortMentorshipSubparagraph2c: 'This program assists those who have never been mentored learn how to be an effective mentor and guide others as they grow in their own leadership. They will learn how to guide effective conversations and behavioral interviews as they guide the candidate through the program.',
    cohortMentorshipSubtitle3: 'Leaving a Legacy',
    cohortMentorshipSubparagraph3a: 'We have received the baton from those who have walked this path ahead of us and those candidates that we invest in are those to whom we will eventually pass our baton. We need to build upon the lives of those before us and as our race comes to an end, we can have confidence that we have faithfully passed on the baton to others. ',
    cohortMentorshipSubparagraph3b: '{0} is intended to help each of us in this process – those who are prepared to share their lives with others as mentors and those who desire to be mentored. In reality, as a mentor you will also learn a great deal by engaging in this process with a candidate.',
    cohortMentorshipCtirTitle: 'Coaching thru Intentional Relationships',
    // Content Outline
    contentOutlineTitle: 'Content Outline',
    contentOutlineParagraph1: 'This Coaching thru Intentional Relationships program is the foundation for the MCNet Christian Leadership Institute. It reflects our values, but is designed as a more formalized program for those who are called to leadership in the Church. To accomplish this, candidates must enroll in the MCNet Christian Leadership Institute. The first four modules in this program complete the Certificate in Christian Leadership and are a prerequisite for the final four modules completing the MCNet Advanced Certificate of Christian Ministry.',
    contentOutlineSubtitle1: 'Certificate in Christian Leadership',
    contentOutlineSubparagraph1a: 'All Candidates enrolling in this program should be involved in some level of ministry and have some leadership and ministry experience. Though there are no specific academic requirements it is expected that the candidate will be able to express themselves at a post-secondary level.',
    contentOutlineSubparagraph1b: 'The program consists of four modules. Two Leadership Competency Modules and two Technical Competencies for a total of 50 CLU (Christian Leadership Units). The Leadership Competencies are: LC1.0 Personal Life and LC 2.0 Relational Life. The Technical Competencies are: TC3.0 Biblical Foundations and TC4.0 Theological Foundations.',
    contentOutlineSubparagraph1c: 'Upon achieving a satisfactory proficiency in each competency the candidate will receive the corresponding Units to a maximum of 50 Units which will signify the completion of the program with the recognition of a MCNet Certificate in Christian Leadership. This achievement is recognized throughout the International Ministry Coaching Network of ministries.',
    contentOutlineSubtitle2: 'Advanced Certificate in Christian Ministry',
    contentOutlineSubparagraph2a: 'All candidates for the Advanced Certificate must have successfully completed the Certificate of Christian Leadership. The program consists of four modules. Two Leadership Competency Modules and two Technical Competencies for a total of 50 CMU (Christian Ministry Units). The Leadership Competencies are: LC5.0 Organizational Life and LC 6.0 Ministry Life. The Technical Competencies are: TC7.0 Missional Foundations and TC8.0 Equipping Foundations.',
    contentOutlineSubparagraph2b: 'Upon achieving a satisfactory proficiency in each competency the candidate will receive the corresponding Units to a maximum of 50 Units which will signify the completion of the program with the recognition of a MCNet Advanced Certificate in Christian Ministry (for a total of 100 CMU including the 50 CLU earned in the prerequisite Leadership Certificate). This achievement is recognized throughout the International Ministry Coaching Network of ministries.',
    contentOutlineLc1: 'LC 1.0 Personal Life (16 CLU)',
    contentOutlineLc1_10: 'LC 1.10 Godly Character (2 CLU)',
    contentOutlineLc1_20: 'LC 1.20 Core Values (2 CLU)',
    contentOutlineLc1_30: 'LC 1.30 Spiritual Disciplines (2 CLU)',
    contentOutlineLc1_40: 'LC 1.40 Spiritual Gifts (2 CLU)',
    contentOutlineLc1_50: 'LC 1.50 Ministry Calling (2 CLU)',
    contentOutlineLc1_60: 'LC 1.60 Leadership Philosophy (2 CLU)',
    contentOutlineLc1_70: 'LC 1.70 Leadership Capacity (2 CLU)',
    contentOutlineLc1_80: 'LC 1.80 Lifelong Learning (2 CLU)',
    contentOutlineLc2: 'LC 2.0 Relational Life (14 CLU)',
    contentOutlineLc2_10: 'LC 2.10 Emotional Intelligence (2 CLU)',
    contentOutlineLc2_20: 'LC 2.20 Team Dynamics (2 CLU)',
    contentOutlineLc2_30: 'LC 2.30 Communication (2 CLU)',
    contentOutlineLc2_40: 'LC 2.40 Mentoring Intelligence (2 CLU)',
    contentOutlineLc2_50: 'LC 2.50 Interpersonal Health (2 CLU)',
    contentOutlineLc2_60: 'LC 2.60 Marriage and Family (2 CLU)',
    contentOutlineLc2_70: 'LC 2.70 Cultural and Generational Intelligence (2 CLU)',
    contentOutlineTc3: 'TC 3.0 Biblical Foundations (10 CLU)',
    contentOutlineTc3_10: 'TC 3.10 Biblical Knowledge (4 CLU)',
    contentOutlineTc3_20: 'TC 3.20 Biblical Interpretation (3 CLU)',
    contentOutlineTc3_30: 'TC 3.30 Biblical Communication (3 CLU)',
    contentOutlineTc4: 'TC 4.0 Theological Foundations (10 CLU)',
    contentOutlineTc4_10: 'TC 4.10 Historical Theology (4 CLU)',
    contentOutlineTc4_20: 'TC 4.20 Basic Theology (3 CLU)',
    contentOutlineTc4_30: 'TC 4.30 Applied Theology (3 CLU)',
    contentOutlineLc5: 'LC 5.0 Organizational Life (16 CMU)',
    contentOutlineLc5_10: 'LC 5.10 Organizational Culture (2 CMU)',
    contentOutlineLc5_20: 'LC 5.20 System Analysis (2 CMU)',
    contentOutlineLc5_30: 'LC 5.30 Ministry Models (2 CMU)',
    contentOutlineLc5_40: 'LC 5.40 Organizational Change (2 CMU)',
    contentOutlineLc5_50: 'LC 5.50 Strategic Planning (2 CMU)',
    contentOutlineLc5_60: 'LC 5.60 Resource Management (2 CMU)',
    contentOutlineLc5_70: 'LC 5.70 Leadership Development (2 CMU)',
    contentOutlineLc5_80: 'LC 5.80 Leadership Transition (2 CMU)',
    contentOutlineLc6: 'LC 6.0 Ministry Life (10 CMU)',
    contentOutlineLc6_10: 'LC 6.10 Fivefold Functions (3 CMU)',
    contentOutlineLc6_20: 'LC 6.20 Fivefold Leadership (3 CMU)',
    contentOutlineLc6_30: 'LC 6.30 Fivefold Ministry (4 CMU)',
    contentOutlineTc7: 'TC 7.0 Missional Foundations (9 CMU)',
    contentOutlineTc7_10: 'TC 7.10 Cultural Intelligence (3 CMU)',
    contentOutlineTc7_20: 'TC 7.20 Bridging Cultures (3 CMU)',
    contentOutlineTc7_30: 'TC 7.30 Multiplication (3 CMU)',
    contentOutlineTc8: 'TC 8.0 Equipping Foundations (15 CMU)',
    contentOutlineTc8_10: 'TC 8.10 Apostolic Capacity (3 CMU)',
    contentOutlineTc8_20: 'TC 8.20 Prophetic Capacity (3 CMU)',
    contentOutlineTc8_30: 'TC 8.30 Evangelistic Capacity (3 CMU)',
    contentOutlineTc8_40: 'TC 8.40 Pastoral Capacity (3 CMU)',
    contentOutlineTc8_50: 'TC 8.50 Teaching Capacity (3 CMU)',
    // International team
    internationalTeamTitle: 'The International Team',
    // Approved Mentors
    approvedMentorsTitle: 'The Approved Mentors',
    approvedMentorsRegionNorthAmerica: 'North America',
    approvedMentorsRegionLatinAmerica: 'Latin America',
    approvedMentorsRegionEurasia: 'Eurasia',
    // Persons
    dougFriesenName: 'Doug Friesen',
    dougFriesenBlurb: 'Doug Friesen has been involved in every aspect of leadership training over his lifetime from formal to informal, church based to educational institutions. As a church planter and pastor his focus has always been to equip leaders to better serve the church. His experience also includes being a mediator in church conflict, helping churches through transition and mentoring the next generation of leaders He has authored several leadership training materials used throughout the network. He is currently the president of CLIMB Canada, which is part of the MCNet International network. Together with Phil, he is the co-creator of this competency based program.',
    philJeskeName: 'Phil Jeske',
    philJeskeBlurb: 'Phil Jeske (PhD) has been a missionary for most of his adult life, living in both Africa and Europe. His passion has been to train leaders and has been involved in designing several innovative training programs around the world. His focus has been on mentoring the next generation and he has written several books on the subject. He is currently the president of International Christian Ministries which is a mission organization that initiated the Ministry Coaching Network. Having been engaged in innovative leadership training models his whole life, co-creating this program with Doug has enabled him to combine a lifetime of experience and training.',
    carlosPimentalName: 'Carlos Pimental',
    carlosPimentalBlurb: 'Carlos Pimental is a seasoned leader who has had a varied career of leading a variety of mission organizations ranging from humanitarian aid agencies to leadership training organizations. Over a lifetime he has founded several types of organizations and provides leadership as a board member on several such organizations. His focus and expertise is on developing teams and releasing young leaders to reach their full potential. Based in the Dominican Republic, he is the Executive Director of a mission organization and president of an organization providing leadership training. His vision for training includes all of Latin America and as a coach with MCNet he is the regional coordinator of the program for Latin America.',
    raydelRiquelmeName: 'Raydel Riquelme',
    raydelRiquelmeBlurb: 'Raydel Riquelme grew up in Cuba and this experience has affected his priorities and passions in the ministry in which he is now involved. While in Cuba he was a respected medical surgeon, but felt the call of God to focus his energies to train leaders. He currently resides in the Dominican Republic so that he can better serve the leaders of Cuba. His vision extends beyond the Caribbean to include all of Latin America. He has been a coach with MCNet for many years and has developed an innovative leadership training program being used in DR, which is now sought after by other countries in the region. He is an innovative thinker who never loses sight of the focus on mentoring leadership through intentional relationships.',
    alexiGorbachevName: 'Alexi Gorbachev',
    alexiGorbachevBlurb: 'Alexei Gorbachev (PhD) is currently the director of a seminary in Moscow. Over the last years he has helped bring about innovative change to its programs in order to meet the changing needs in Russia. With a focus on mentoring the next generation, he and his team have created many online and extension training programs that are meeting the needs of current Russian leadership. He is also a pastor and a coach for the MCNetwork, training leaders in several other countries. His passion is to see the evangelical church in Russia become relevant within the Russian context and he is involved in several endeavors such as the Lausanne Committee as well as a member of other international organizations.',
    maxymOliferovskiName: 'Maxym Oliferovski',
    maxymOliferovskiBlurb: 'Maxym Oliferovski is a church planter and director of a mission agency in Ukraine. He in intentional about building teams and continues to do this within his varied ministry contexts. He is currently the team leader of a church plant as well as provides oversight to several other churches in his region. As the leader of a missions agency that ministers to displaced families he serves his team as they face the challenges of ministering to needy families in eastern Ukraine. He continues to open up doors in his country for leadership training and is a coach with MCNet in Ukraine.',
    rafikName: 'Rafik',
    rafikBlurb: 'Rafik was born and raised in East Africa (Tanzania). When he was a young adult he had a powerful conversion to Jesus from his Muslim upbringing. As an evangelist he preached throughout Tanzania and saw several churches established, both within the African and Indian communities. His passion to reach others with the Gospel continued when he emigrated to Canada many years ago. Currently, he is a chaplain with a mission that serves the homeless and those struggling with additions. His varied church as well as cultural experience has given him a platform of ministry that provides a unique impact for the Gospel.',
    tatianaGorbachevaName: 'Tatiana Gorbacheva',
    tatianaGorbachevaBlurb: 'Tatiana Gorbacheva (PhD) has served in various key leadership capacities within her denomination and is a gifted teacher and communicator. As a coach in the Ministry Coaching Network (MCNet) she has led many mentoring cohorts and has been a key motivator in helping leaders see the need for such relationships. With her degree in theology and counselling she also has started a family counselling ministry in Moscow. Tatiana travels regularly with Russian as well as internationally to teach and train leaders, as well as together with her husband, providing leadership in her church and the seminary.',
    karlSchneiderName: "Karl Schneider",
    karlSchneiderBlurb: "Karl Schneider currently pastors a church in eastern Germany and has been instrumental in connecting other church planters in his city and region. Over the years he has also traveled extensively internationally as both an evangelist and teacher. He has a passion for his generation and is actively engaged in mentoring young leaders in his city. As a coach with MCNet he also continues to share his experience of mentoring young leaders beyond the borders of Germany and Europe.",
    valeraKudaevName: "Valera Kudaev",
    valeraKudaevBlurb: "Valera Kudaev has a passion to train and release the next generation of leaders in Russia. He currently functions as a pastor as well as being a gifted teacher and coach of church teams. In his denomination he has released many from the next generation that he and his wife have mentored over the years. He currently lives in St. Petersburg and is involved on a church team ministering to families and continuing to raise up the next generation of leaders. His practical church experience enables him to be a valuable coach within the MCNetwork.",
    narinaKudaevName: "Narina Kudaev",
    narinaKudaevBlurb: "Narina Kudaev is a gifted linguist and translator, a gift she has used extensively in her ministry. As well as working alongside of her husband in training leaders, she has also been involved in marriage and family counselling. As an educator and educational administrator she has provided leadership in a Christian school and is currently serving within an International school in St. Petersburg, Russia. She serves as a coach within the MCNetwork using her personal experience of mentoring young leaders and releasing them into ministry.",
  },
  es: {
    // HTTP 404 Page
    http404Title: 'Not Found',
    http404Text: "Sorry, the page you're looking for doesn't appear to exist.",
    // Organization
    orgShortName: 'MCNet ILC',
    orgName: 'MCNet Instituto de Liderazgo Cristiano',
    // Header
    headerLogoAltText: 'Logo del MCNet ILC',
    // Nav bar
    navProgram: 'El Programa',
    navInternationalTeam: 'El Equipo Internacional',
    navApprovedMentors: 'Los Mentores Aprobados',
    navDonate: 'Donor',
    navLogin: 'Acceso',
    // Footer
    footerCopyRight: '©{0} CLIMB Intercultural Society',
    // Welcome
    welcomeTitle: 'Bienvenidos al Instituto de Liderazgo Cristiano',
    welcomeText: 'El Instituto de Liderazgo Cristiano MCNet es un ministerio de Mentoría Global Basado en Competencias para aquellos llamados a la obra ministerial a tiempo completo. Es una iniciativa del Ministerio Cristiano Internacional y de CLIMB, basado en el programa de Coaching a través de Relaciones Intencionales. El término del programa otorga un Certificado en Liderazgo Cristiano y un Certificado Avanzado en el Ministerio Cristiano. Este es un nuevo enfoque innovador de capacitación ministerial formal que va más allá de simplemente concentrarse en un aprendizaje basado en tiempo o credenciales. La Red de Capacitación Ministerial (MCNet) es una comunidad global de líderes quienes comparten los valores del liderazgo y la mentoría de siervos como el cimiento para reproducir iglesias saludables.',
    // Links
    linkSeeTheTeam: 'Ver El Equipo',
    linkSeeTheProgram: 'Ver El Programa',
    linkSeeTheOrientationManual: 'Ver El Manual de Orientación',
    // Program 
    programTitle: 'El Programa',
    // Competency Based Leadership Training
    competencyTitle: 'Entrenamiento de Liderazgo Basado en Competencias',
    competencyParagraph1: 'El aprendizaje basado en competencias es un modelo de formación que se centra en los resultados que se traducen en eficacia en las disciplinas profesionales. Este modelo de aprendizaje se utiliza comúnmente en la formación profesional, la formación técnica, la formación médica o en cualquier área donde el foco esté en las habilidades utilizadas para realizar tareas específicas. El aprendizaje basado en competencias se puede contrastar tanto con el aprendizaje basado en el tiempo como con el basado en credenciales.',
    competencyParagraph2: 'La mayoría de los modelos académicos enfatizan la medición del aprendizaje utilizando las categorías de tiempo invertido, créditos obtenidos y pruebas basadas en el recuerdo del conocimiento. El aprendizaje basado en competencias es un sistema de aprendizaje basado en un conjunto predeterminado de competencias, evaluación del alumno en función de esas competencias, desarrollo de una pista de aprendizaje única para cada alumno, evaluación del progreso del alumno y prueba del alumno en un contexto de trabajo real.',
    competencyParagraph3: 'Hay varias ventajas claras de un modelo de aprendizaje basado en competencias:',
    competencySubtitle1: 'Reconoce las habilidades y experiencias del líder',
    competencySubparagraph1: 'El punto de partida en el modelo basado en competencias no es un programa predeterminado que un estudiante debe completar para recibir un título u otro reconocimiento. El punto de partida en este modelo es la evaluación del carácter, los conocimientos, las habilidades y la experiencia del candidato con el fin de determinar qué le falta al candidato para que se pueda diseñar un proceso de desarrollo específicamente para el candidato.',
    competencySubtitle2: 'Centra en las necesidades del líder',
    competencySubparagraph2: 'En este sentido, el elemento tiempo del proceso no es un número predeterminado de semestres o años de educación. El tiempo empleado en este proceso depende del nivel de desarrollo del candidato como líder. Los candidatos más maduros o experimentados tomarán menos tiempo para completar el programa porque pueden concentrarse en lo que necesitan y no tener que dedicar tiempo a áreas y materias en las que ya demuestran competencia. Poder concentrarse en lo que se necesita también tiene la ventaja de permitir que el candidato se enfoque en áreas donde se necesita una mayor efectividad para el desarrollo de su liderazgo en lugar de repetir cosas en las que ya demuestra tener un alto grado de experiencia.',
    competencySubtitle3: 'Se enfoca en aprender mientras hace',
    competencySubparagraph3: 'También es mejor si un candidato está involucrado en el ministerio a un nivel de liderazgo que sea apropiado para su nivel actual de competencia en carácter, conocimiento, habilidades y experiencia. Es mejor para ellos aprender "en el trabajo" en situaciones reales, siendo asesorados y obteniendo experiencia real en lugar de un aprendizaje hipotético en un entorno de aprendizaje artificial. Un enfoque basado en competencias junto con la participación en el liderazgo del ministerio permite a los candidatos contribuir de manera significativa a medida que atraviesan el proceso de capacitación. También comunica la idea de que la capacitación y el aprendizaje continuos son una parte integral del liderazgo ministerial. Una de las realidades actuales del ministerio es el hecho de que más líderes están abandonando el ministerio de los que están siendo desplegados. La capacitación en liderazgo basada en competencias ayuda a abordar este problema al hacer que los líderes participen en el ministerio durante el proceso de capacitación, al tiempo que cuentan con el beneficio de mentores experimentados que los guían en el camino.',
    competencySubtitle4: 'Alinea la preparación con la experiencia y la competencia',
    competencySubparagraph4: 'La capacitación basada en competencias también tiene la ventaja de desplegar líderes que han sido cuidadosamente observados y evaluados a un nivel muy personal en contraposición a un graduado típico de un programa educativo teológico que ingresa al ministerio al graduarse, pero que no necesariamente tiene la experiencia adecuada que los prepara para la tarea. Una barrera seria para un liderazgo eficaz y sostenible es el desequilibrio que a menudo se produce entre la preparación y la experiencia de un líder. Algunos tienen una amplia educación y formación, pero carecen de experiencia. Otros son empujados a puestos para los que tienen poca formación o preparación educativa. Un enfoque basado en competencias enfatiza un equilibrio entre la preparación y la competencia en comparación con su nivel de responsabilidad. Mantener este equilibrio en mente y aplicar las prácticas aprendidas en un sistema basado en competencias permitirá que un líder sea eficaz y duradero.',
    // Cohort Mentorship
    cohortMentorshipTitle: 'Mentoría De Cohorte',
    cohortMentorshipParagraph1: 'Para obtener más detalles sobre el Componente de Mentoría, consulte el {0}.',
    cohortMentorshipOrientationManual: 'Manual de Orientación',
    cohortMentorshipParagraph2: 'En el centro de este programa basado en competencias para la formación de líderes se encuentra la relación de mentoría. Como explica esta sesión de la orientación, este no es un concepto nuevo, sino el proceso por el cual Jesús entrenó a sus discípulos, los primeros líderes de la iglesia emergente.',
    cohortMentorshipParagraph3: 'La Mentoría es un valor fundamental de Ministry Coaching Network y es fundamental para toda nuestra capacitación de liderazgo. Sin embargo, muchos no han tenido un mentor y no saben cómo transmitir el liderazgo de esta manera. En consecuencia, el foco pasa a ser la transferencia de conocimiento, más que la transformación de la vida. La Mentoría es una habilidad para la vida que se aprende y esta sesión es una breve descripción general de la Mentoría y proporciona las habilidades necesarias para ser un mentor eficaz.',
    cohortMentorshipParagraph4: 'Generalmente, la mentoría viene junto a otra persona y la ayuda a alcanzar sus metas; ayudar a alguien a alcanzar su máximo potencial. En el contexto de este programa, está ayudando a un candidato al ministerio a aumentar su competencia en varias competencias relacionadas con el servicio efectivo en el cuerpo de Cristo. Idealmente la mentoría relacional debe abarcar y facilitar el desarrollo en equilibrio del carácter (corazón), el conocimiento (cabeza/mente) y las habilidades (manos).',
    cohortMentorshipParagraph5: 'La Mentoría Inteligente se refiere a las habilidades tanto del individuo como de la organización para fomentar tipos de relaciones de mentoría. Incluso los líderes experimentados pueden tener dificultades para guiar a otros debido al hecho de que no conocen las habilidades básicas necesarias para guiar a otra persona. Es posible que ni siquiera sepan que se trata de habilidades aprendidas.',
    cohortMentorshipSubtitle1: 'La Base',
    cohortMentorshipSubparagraph1: 'El liderazgo de servicio, como se describe en la Biblia, es el único tipo de liderazgo que reproduce la vida en otros. Es solo la vida genuina la que puede reproducir otra vida. Si no tenemos este ADN sano, reproduciremos líderes que nacen muertos, o líderes que no pueden reproducir a otros. Esto da como resultado la producción de clones o aquellos que dependen de nosotros.',
    cohortMentorshipSubtitle2: 'El Enfoque',
    cohortMentorshipSubparagraph2a: 'Liderar a otros se basa en una relación y no en un enfoque jerárquico o de posición. Se concentra en soltar a otros hacia su llamado de vida y no el controlarlos para que llenen el nuestro. Se basa en la rendición de cuenta y la vulnerabilidad, y no en una actitud de independiencia. Es un liderazgo auténtico que tiene su foco de atención en el Reino.',
    cohortMentorshipSubparagraph2b: 'NO está limitado al discipulado espiritual, aunque el proceso es similar al discipulado. Su foco está más dirigido hacia el desarrollo del liderazgo de la persona integral. Tampoco se trata del enfoque no saludable de una paternidad espiritual donde un “padre” trata de controlar a los otros como niños pequeños. El mentor tampoco necesita ser perfecto o, un consejero profesional, o un anciano patriarcal.',
    cohortMentorshipSubparagraph2c: 'Este programa ayuda a aquellos, quienes nunca han recibido mentoría, a aprender cómo ser un mentor efectivo y guiar a otros en la medida en que crecen en su propio liderazgo. Aprenderán a sostener conversaciones efectivas y entrevistas sobre la conducta al guíar a un candidato a lo largo del programa.',
    cohortMentorshipSubtitle3: 'Dejando un Legado',
    cohortMentorshipSubparagraph3a: 'Hemos recibido la antorcha (el testigo) de quienes han recorrido este camino por delante de nosotros y esos candidatos en los que invertimos son aquellos a quienes eventualmente pasaremos nuestra antorcha. Necesitamos construir sobre las vidas de quienes nos precedieron y, a medida que nuestra carrera llega a su fin, podemos tener la confianza de que hemos pasado fielmente la antorcha a otros.',
    cohortMentorshipSubparagraph3b: '{0} tiene como objetivo ayudar a cada uno de nosotros en este proceso: aquellos que están preparados para compartir sus vidas con otros como mentores y aquellos que desean ser mentores. En realidad, como mentor, también aprenderá mucho al participar en este proceso con un candidato.',
    cohortMentorshipCtirTitle: 'El coaching a través de relaciones intencionales',
    // Content Outline
    contentOutlineTitle: 'Esquema de Contenido',
    contentOutlineParagraph1: 'Este programa Coaching a través de Relaciones Intencionales es el cimiento para el Instituto de Liderazgo Cristiano MCNet. Proyecta nuestros valores, aunque está diseñado como un programa más formalizado para los que son llamados al liderazgo en la Iglesia. Para llevar a cabo esto, los candidatos deben inscribirse en el Instituto de Liderazgo Cristiano (MCNet). Los primeros cuatro módulos de este programa completan el Certificado en Liderazgo Cristiano y son pre-requisito de los cuatro módulos finales del Certificado Avanzado del Ministerio Cristiano MCNet.',
    contentOutlineSubtitle1: 'Certificado en Liderazgo Cristiano',
    contentOutlineSubparagraph1a: 'Todos los candidatos que se inscriban en este programa deben estar involucrados en algún nivel de ministerio y tener algo de liderazgo y experiencia en el ministerio. Aunque no existen requisitos académicos específicos, se espera que el candidato pueda expresarse en un nivel postsecundario.',
    contentOutlineSubparagraph1b: 'El programa consta de cuatro módulos. Dos módulos de competencias de liderazgo y dos competencias técnicas para un total de 50 CLU (Unidades de Liderazgo Cristiano). Las Competencias de Liderazgo son: LC1.0 Vida Personal y LC 2.0 Vida Relacional. Las Competencias Técnicas son: TC3.0 Fundamentos Bíblicos y TC4.0 Fundamentos Teológicos.',
    contentOutlineSubparagraph1c: 'Al lograr un dominio satisfactorio en cada competencia, el candidato recibirá las Unidades correspondientes hasta un máximo de 50 Unidades, lo que significará la finalización del programa con el reconocimiento de un Certificado MCNet en Liderazgo Cristiano. Este logro es reconocido a través de la Red de Coaching Ministerial Internacional de ministerios.',
    contentOutlineSubtitle2: 'Certificado Avanzado en Ministerio Cristiano',
    contentOutlineSubparagraph2b: 'Todos los candidatos para el Certificado Avanzado deben haber completado con éxito el Certificado de Liderazgo Cristiano. El programa consta de cuatro módulos. Dos Módulos de Competencias de Liderazgo y dos Competencias Técnicas para un total de 50 CMU (Unidades de Ministerio Cristiano). Las Competencias de Liderazgo son: LC 5.0 Vida Organizacional y LC 6.0 Vida ministerial. Las Competencias Técnicas son: TC7.0 Mentalidad Misional y TC 8.0 Habilidades de Equipamiento.',
    contentOutlineSubparagraph2c: 'Al lograr un dominio satisfactorio en cada competencia, el candidato recibirá las unidades correspondientes hasta un máximo de 50 Unidades, lo que significará la finalización del programa con el reconocimiento de un Certificado Avanzado MCNet en Ministerio Cristiano (para un total de 100 CMU incluyendo las 50 CLU obtenido en el certificado de liderazgo como requisito previo). Este logro es reconocido a través de la Red de Coaching Ministerial Internacional de ministerios.',
    contentOutlineLc1: 'LC 1.0 Vida Personal (16 CLU)',
    contentOutlineLc1_10: 'LC 1.10 Carácter Piadoso (2 CLU)',
    contentOutlineLc1_20: 'LC 1.20 Valores Centrales (2 CLU)',
    contentOutlineLc1_30: 'LC 1.30 Disciplinas Espirituales (2 CLU)',
    contentOutlineLc1_40: 'LC 1.40 Dones Espirituales (2 CLU)',
    contentOutlineLc1_50: 'LC 1.50 Llamado Ministerial (2 CLU)',
    contentOutlineLc1_60: 'LC 1.60 Filosofía de Liderazgo (2 CLU)',
    contentOutlineLc1_70: 'LC 1.70 Capacidad de Liderazgo (2 CLU)',
    contentOutlineLc1_80: 'LC 1.80 El Aprendizaje Permanente (2 CLU)',
    contentOutlineLc2: 'LC 2.0 Vida Relacional (14 CLU)',
    contentOutlineLc2_10: 'LC 2.10 Inteligencia Emocional (2 CLU)',
    contentOutlineLc2_20: 'LC 2.20 Dinámica de Equipo (2 CLU)',
    contentOutlineLc2_30: 'LC 2.30 Comunicación (2 CLU)',
    contentOutlineLc2_40: 'LC 2.40 Inteligencia Mentoría (2 CLU)',
    contentOutlineLc2_50: 'LC 2.50 Salud Interpersonal (2 CLU)',
    contentOutlineLc2_60: 'LC 2.60 Matrimonio y Familia (2 CLU)',
    contentOutlineLc2_70: 'LC 2.70 Inteligencia Cultural y Generacional (2 CLU)',
    contentOutlineTc3: 'TC 3.0 Fundamentos Bíblicos (10 CLU)',
    contentOutlineTc3_10: 'TC 3.10 Conocimiento Bíblico (4 CLU)',
    contentOutlineTc3_20: 'TC 3.20 Interpretación Bíblica (3 CLU)',
    contentOutlineTc3_30: 'TC 3.30 Comunicación Bíblica (3 CLU)',
    contentOutlineTc4: 'TC 4.0 Fundamentos Teológicos (10 CLU) ',
    contentOutlineTc4_10: 'TC 4.10 Teología Histórica (4 CLU)',
    contentOutlineTc4_20: 'TC 4.20 Teología Básica (3 CLU)',
    contentOutlineTc4_30: 'TC 4.30 Teología Aplicada (3 CLU)',
    contentOutlineLc5: 'LC 5.0 Vida Organizacional (16 CMU)',
    contentOutlineLc5_10: 'LC 5.10 Cultura Organizacional (2 CMU)',
    contentOutlineLc5_20: 'LC 5.20 Análisis del Sistema (2 CMU)',
    contentOutlineLc5_30: 'LC 5.30 Modelos de Ministerios (2 CMU)',
    contentOutlineLc5_40: 'LC 5.40 Cambio Organizacional (2 CMU)',
    contentOutlineLc5_50: 'LC 5.50 Planificacion Estratégica (2 CMU)',
    contentOutlineLc5_60: 'LC 5.60 Administracion de Recursos (2 CMU)',
    contentOutlineLc5_70: 'LC 5.70 Desarrollo de Liderazgo (2 CMU)',
    contentOutlineLc5_80: 'LC 5.80 Transición de Liderazgo (2 CMU)',
    contentOutlineLc6: 'LC 6.0 Vida Ministerial (10 CMU)',
    contentOutlineLc6_10: 'LC 6.10 Vida Ministerial (3 CMU)',
    contentOutlineLc6_20: 'LC 6.20 Liderazgo de Cinco Partes (3 CMU)',
    contentOutlineLc6_30: 'LC 6.30 Ministerio de Cinco Partes del Ministerio (4 CMU)',
    contentOutlineTc7: 'TC 7.0 Fundamentos de Misión (9 CMU)',
    contentOutlineTc7_10: 'TC 7.10 Inteligencia Cultural (3 CMU)',
    contentOutlineTc7_20: 'TC 7.20 Enlazando Culturas (3 CMU)',
    contentOutlineTc7_30: 'TC 7.30 Multiplicacion (3 CMU)',
    contentOutlineTc8: 'TC 8.0 Fundamentos de Equipamiento (15 CMU)',
    contentOutlineTc8_10: 'TC 8.10 Capacidad Apostólica (3 CMU)',
    contentOutlineTc8_20: 'TC 8.20 Capacidad Profética (3 CMU)',
    contentOutlineTc8_30: 'TC 8.30 Capacidad Evangelística (3 CMU)',
    contentOutlineTc8_40: 'TC 8.40 Capacidad Pastoral (3 CMU)',
    contentOutlineTc8_50: 'TC 8.50 Capacidad de Enseñanza (3 CMU)',
    // International team
    internationalTeamTitle: 'The International Team',
    // Approved Mentors
    approvedMentorsTitle: 'The Approved Mentors',
    approvedMentorsRegionNorthAmerica: 'North America',
    approvedMentorsRegionLatinAmerica: 'Latin America',
    approvedMentorsRegionEurasia: 'Eurasia',
    // Persons
    dougFriesenName: 'Doug Friesen',
    dougFriesenBlurb: 'Doug Friesen has been involved in every aspect of leadership training over his lifetime from formal to informal, church based to educational institutions. As a church planter and pastor his focus has always been to equip leaders to better serve the church. His experience also includes being a mediator in church conflict, helping churches through transition and mentoring the next generation of leaders He has authored several leadership training materials used throughout the network. He is currently the president of CLIMB Canada, which is part of the MCNet International network. Together with Phil, he is the co-creator of this competency based program.',
    philJeskeName: 'Phil Jeske',
    philJeskeBlurb: 'Phil Jeske (PhD) has been a missionary for most of his adult life, living in both Africa and Europe. His passion has been to train leaders and has been involved in designing several innovative training programs around the world. His focus has been on mentoring the next generation and he has written several books on the subject. He is currently the president of International Christian Ministries which is a mission organization that initiated the Ministry Coaching Network. Having been engaged in innovative leadership training models his whole life, co-creating this program with Doug has enabled him to combine a lifetime of experience and training.',
    carlosPimentalName: 'Carlos Pimental',
    carlosPimentalBlurb: 'Carlos Pimental is a seasoned leader who has had a varied career of leading a variety of mission organizations ranging from humanitarian aid agencies to leadership training organizations. Over a lifetime he has founded several types of organizations and provides leadership as a board member on several such organizations. His focus and expertise is on developing teams and releasing young leaders to reach their full potential. Based in the Dominican Republic, he is the Executive Director of a mission organization and president of an organization providing leadership training. His vision for training includes all of Latin America and as a coach with MCNet he is the regional coordinator of the program for Latin America.',
    raydelRiquelmeName: 'Raydel Riquelme',
    raydelRiquelmeBlurb: 'Raydel Riquelme grew up in Cuba and this experience has affected his priorities and passions in the ministry in which he is now involved. While in Cuba he was a respected medical surgeon, but felt the call of God to focus his energies to train leaders. He currently resides in the Dominican Republic so that he can better serve the leaders of Cuba. His vision extends beyond the Caribbean to include all of Latin America. He has been a coach with MCNet for many years and has developed an innovative leadership training program being used in DR, which is now sought after by other countries in the region. He is an innovative thinker who never loses sight of the focus on mentoring leadership through intentional relationships.',
    alexiGorbachevName: 'Alexi Gorbachev',
    alexiGorbachevBlurb: 'Alexei Gorbachev (PhD) is currently the director of a seminary in Moscow. Over the last years he has helped bring about innovative change to its programs in order to meet the changing needs in Russia. With a focus on mentoring the next generation, he and his team have created many online and extension training programs that are meeting the needs of current Russian leadership. He is also a pastor and a coach for the MCNetwork, training leaders in several other countries. His passion is to see the evangelical church in Russia become relevant within the Russian context and he is involved in several endeavors such as the Lausanne Committee as well as a member of other international organizations.',
    maxymOliferovskiName: 'Maxym Oliferovski',
    maxymOliferovskiBlurb: 'Maxym Oliferovski is a church planter and director of a mission agency in Ukraine. He in intentional about building teams and continues to do this within his varied ministry contexts. He is currently the team leader of a church plant as well as provides oversight to several other churches in his region. As the leader of a missions agency that ministers to displaced families he serves his team as they face the challenges of ministering to needy families in eastern Ukraine. He continues to open up doors in his country for leadership training and is a coach with MCNet in Ukraine.',
    rafikName: 'Rafik',
    rafikBlurb: 'Rafik was born and raised in East Africa (Tanzania). When he was a young adult he had a powerful conversion to Jesus from his Muslim upbringing. As an evangelist he preached throughout Tanzania and saw several churches established, both within the African and Indian communities. His passion to reach others with the Gospel continued when he emigrated to Canada many years ago. Currently, he is a chaplain with a mission that serves the homeless and those struggling with additions. His varied church as well as cultural experience has given him a platform of ministry that provides a unique impact for the Gospel.',
    tatianaGorbachevaName: 'Tatiana Gorbacheva',
    tatianaGorbachevaBlurb: 'Tatiana Gorbacheva (PhD) has served in various key leadership capacities within her denomination and is a gifted teacher and communicator. As a coach in the Ministry Coaching Network (MCNet) she has led many mentoring cohorts and has been a key motivator in helping leaders see the need for such relationships. With her degree in theology and counselling she also has started a family counselling ministry in Moscow. Tatiana travels regularly with Russian as well as internationally to teach and train leaders, as well as together with her husband, providing leadership in her church and the seminary.',
    karlSchneiderName: "Karl Schneider",
    karlSchneiderBlurb: "Karl Schneider currently pastors a church in eastern Germany and has been instrumental in connecting other church planters in his city and region. Over the years he has also traveled extensively internationally as both an evangelist and teacher. He has a passion for his generation and is actively engaged in mentoring young leaders in his city. As a coach with MCNet he also continues to share his experience of mentoring young leaders beyond the borders of Germany and Europe.",
    valeraKudaevName: "Valera Kudaev",
    valeraKudaevBlurb: "Valera Kudaev has a passion to train and release the next generation of leaders in Russia. He currently functions as a pastor as well as being a gifted teacher and coach of church teams. In his denomination he has released many from the next generation that he and his wife have mentored over the years. He currently lives in St. Petersburg and is involved on a church team ministering to families and continuing to raise up the next generation of leaders. His practical church experience enables him to be a valuable coach within the MCNetwork.",
    narinaKudaevName: "Narina Kudaev",
    narinaKudaevBlurb: "Narina Kudaev is a gifted linguist and translator, a gift she has used extensively in her ministry. As well as working alongside of her husband in training leaders, she has also been involved in marriage and family counselling. As an educator and educational administrator she has provided leadership in a Christian school and is currently serving within an International school in St. Petersburg, Russia. She serves as a coach within the MCNetwork using her personal experience of mentoring young leaders and releasing them into ministry.",
  },
  ru: {
    // HTTP 404 Page
    http404Title: 'HTTP 404 не найден',
    http404Text: "Извините, страница, которую вы ищете, не существует.",
    // Organization
    orgShortName: 'MCNet ИХЛ', // TODO
    orgName: 'MCNet Институт Христианского Лидерства', // TODO
    // Header
    headerLogoAltText: 'Логотип MCNet ИХЛ', // TODO
    // Nav bar
    navProgram: 'Программа',
    navInternationalTeam: 'Международная команда', // TODO
    navApprovedMentors: 'Квалифицированные наставники', // TODO
    navDonate: 'Пожертвовать', // TODO
    navLogin: 'Авторизоваться', // TODO
    // Footer
    footerCopyRight: '©{0} CLIMB Intercultural Society', // TODO
    // Welcome
    welcomeTitle: 'Добро пожаловать в Институт Христианского Лидерства',
    welcomeText: 'Институт христианского лидерства MCNet - это международная программа наставничества, основанная на компетенциях, для людей, которые призваны к христианскому служению. Он создан по инициативе Международного христианского служения ICM (www.icmcanada.org) и CLIMB (www.climbintercultural.ca) на основе программы Тренерство в служении посредством осознанных взаимоотношений. По окончании программы выдаются сертификаты христианского лидера и христианского служителя (продвинутый уровень). Речь идет о новом инновационном подходе к обучению служителей, который выходит за рамки простого изучения материала, ориентированного на время или получение диплома. MCNet (Международное тренерское служение) - это международное объединение лидеров, разделяющих ценности служащего лидерства и наставничества как основы возрождения здоровых церквей.',
    // Links
    linkSeeTheTeam: 'Команда',
    linkSeeTheProgram: 'Программа',
    linkSeeTheOrientationManual: 'Руководство',
    // Program 
    programTitle: 'Программа',
    // Competency Based Leadership Training
    competencyTitle: 'Обучение, основанное на компетенциях',
    competencyParagraph1: 'Обучение, основанное на компетенциях, - это модель обучения, направленная на достижение результатов, которые выражаются в высокой эффективности освоения различных видов профессиональной деятельности. Данная модель обучения применяется в профессиональной, технической, медицинской и других видах подготовки, где основное внимание уделяется освоению навыков, необходимых для выполнения конкретных задач. Обучение, основанное на компетенциях, можно противопоставить обучению, ориентированному на время и квалификацию.',
    competencyParagraph2: 'В большинстве образовательных моделей обучение измеряется с помощью таких категорий, как затраченное время, количество полученных зачётов и выполнение тестов на проверку знаний. Обучение на основе компетенций - это система подготовки служителей, основанная на определённом наборе компетенций, оценки обучаемого на основе этих компетенций, составления уникальной для каждого обучаемого программы обучения, анализа достигнутого обучаемым уровня и проверки обучаемого в условиях реальной деятельности.',
    competencyParagraph3: 'Модель обучения на основе компетенций имеет ряд очевидных преимуществ:',
    competencySubtitle1: 'Обучение, основанное на компетенциях, учитывает уровень лидерских навыков и опыта',
    competencySubparagraph1: 'В модели, основанной на компетенциях, отправной точкой является не заранее определенная программа, которую обучаемый должен пройти для получения диплома или иного документа. Исходной точкой является оценка характера, знаний, навыков и опыта обучаемого с целью определения того, чего ему не хватает, чтобы разработать индивидуальный процесс повышения квалификации.',
    competencySubtitle2: 'Обучение, основанное на компетенциях, ориентировано на нужды лидера',
    competencySubparagraph2: 'С этой точки зрения время, затрачиваемое на процесс, не определяется количеством семестров или лет обучения. Время обучения зависит от уровня развития лидера. Более зрелые или опытные обучаемые затратят меньше времени на прохождение программы, поскольку смогут сфокусироваться на том, что им необходимо, и не станут уделять время компетенциям, которые уже освоены. Кроме того, возможность сосредоточиться на том, что необходимо, позволяет сконцентрироваться на тех областях, в которых необходимо повысить эффективность работы, развитие лидерских качеств, и не повторяться в тех сферах, в которых обучаемые и так уже демонстрируют высокий уровень знаний и умений.',
    competencySubtitle3: 'Обучение, основанное на компетенциях, нацелено на освоение знаний в ходе практической деятельности, а не просто на получение академического образования',
    competencySubparagraph3: 'Желательно, чтобы обучаемый был вовлечён в служение на уровне лидерства, который соответствует уровню развития его характера, знаний, навыков и опыта. Для обучаемого лучше учиться "на деле", в реальной ситуации, под руководством наставника, приобретая реальный опыт, а не получая гипотетические знания в искусственно созданной учебной среде. Направленность на развитие компетенций, а также участие в лидерском служении позволят в процессе обучения сделать существенный вклад в собственное развитие. Кроме того, постоянное обучение и тренинги являются неотъемлемой частью служения. В настоящее время в служении существует проблема, связанная с прекращением работы многих лидеров во время обучения. Программа обучения лидерству, основанная на компетенциях, помогает решить эту проблему, поскольку в ходе учебного процесса лидеры вовлекаются в служение, а опытные наставники помогают им на этом пути.',
    competencySubtitle4: 'Обучение, основанное на компетенциях, обеспечивает соответствие качества подготовки опыту и навыкам',
    competencySubparagraph4: 'Обучение, основанное на компетенциях, дает преимущество в том, что на служение приходят лидеры, прошедшие серьезную проверку и оценку на индивидуальном уровне, в отличие от выпускников богословских учебных заведений, которые сразу после окончания учебы приступают к служению, но при этом не всегда имеют соответствующий опыт выполнения поставленной задачи. Существенным препятствием на пути к эффективному и устойчивому лидерству является несоответствие между подготовкой и опытностью лидера. Многие из них имеют достаточный уровень образования и подготовки, но им не хватает опыта. Другие занимают позиции, не имеющие достаточной степени подготовки или образования. Компетентностный подход позволяет найти золотую середину между подготовкой, уровнем знаний и уровнем ответственности. Поддержание такого баланса и применение практических навыков, полученных в системе компетентностного подхода, позволит лидеру быть не только результативным, но и работоспособным долгие годы.',
    // Cohort Mentorship
    cohortMentorshipTitle: 'Групповое наставничество',
    cohortMentorshipParagraph1: 'Более подробная информация об аспекте наставничества приведена в {0}.', // TODO
    cohortMentorshipOrientationManual: 'руководстве', // TODO
    cohortMentorshipParagraph2: 'Центральным элементом программы подготовки служителей, основанной на компетенциях, является наставничество. Как объясняется в вводной части, это не новое понятие, а процесс, с помощью которого Иисус готовил своих учеников - первых лидеров формирующейся церкви.',
    cohortMentorshipParagraph3: 'Наставничество является одной из основных ценностей Международного тренерского служения и главным элементом всех наших программ подготовки лидеров. В то же время многие не занимались наставничеством и не знают, как осуществлять передачу лидерства, используя наставничество. В результате, в центре внимания оказывается скорее передача знаний, а не изменение жизни. Наставничество - это приобретаемый жизненный навык, и данный раздел представляет собой краткий обзор наставничества и описывает навыки, необходимые для эффективного наставничества.',
    cohortMentorshipParagraph4: 'Наставничество - это поддержка и помощь в достижении целей; содействие в раскрытии потенциала. В контексте данной программы наставничество является помощью в повышении уровня лидерских качеств человека, претендующего на служение в Теле Христова. Программа сочетает в себе такие качества, как характер, знания и навыки.',
    cohortMentorshipParagraph5: 'Способность к наставничеству - это умение отдельного человека и всей церкви поощрять и развивать наставнические отношения. Даже опытные лидеры могут испытывать трудности в наставничестве, поскольку не владеют основными навыками, необходимыми для наставничества. Возможно, они даже не осознают, что этим навыкам можно научиться.',
    cohortMentorshipSubtitle1: 'Основание',
    cohortMentorshipSubparagraph1: 'Служащее лидерство, описанное в Библии, - единственный стиль лидерства, позволяющий другим воспроизводить жизнь. Только настоящая жизнь способна порождать другую жизнь. Если в нас нет этой здоровой ДНК, то мы будем воспроизводить мертворожденных лидеров - или лидеров, которые не способны воспитать других. В результате получаются клоны или просто зависимые от нас люди.',
    cohortMentorshipSubtitle2: 'Направленность',
    cohortMentorshipSubparagraph2a: 'В основе этого подхода лежат отношения, а не позиционное или вертикальное лидерство. Главное, позволить другим исполнить свое жизненное призвание, а не контролировать их в соответствии с нашими предпочтениями. В основе также лежит подотчетность и открытость, а не независимость. Это и есть подлинное лидерство, ориентированное на Царство Божие.',
    cohortMentorshipSubparagraph2b: 'Программа НЕ ограничивается духовным ученичеством, хотя сам процесс схож с ученичеством. Он в большей степени направлен на формирование лидера в целом. Речь не идет о нездоровом духовном отцовстве, когда один "отец" пытается контролировать других, как маленьких детей. Наставник не должен быть совершенным, профессионалом-консультантом или старцем-патриархом.',
    cohortMentorshipSubparagraph2c: 'Эта программа поможет тем, кто никогда не имел опыта наставничества, узнать, как стать наставником и помогать другим в развитии их лидерских качеств. В процессе прохождения программы обучающиеся научатся правильно проводить собеседования и поведенческие интервью.',
    cohortMentorshipSubtitle3: 'Оставляя наследие',
    cohortMentorshipSubparagraph3a: 'Мы получили эстафетную палочку от предыдущего поколения, и, в свою очередь, мы передадим её своим подопечным. Мы должны брать пример с тех, кто бежал впереди, и быть уверены в том, что мы сделали всё, чтобы на закате своего служения передать наше видение другим.',
    cohortMentorshipSubparagraph3b: '{0} помогает совершить достойный забег тем, кто готов делиться своей жизнью как наставник, и тем, кто желает быть наставляемым. В реальности каждый из нас одновременно и наставник, и наставляемый. Ступая на предназначенный нам путь, вы и сами возрастёте в процессе наставничества.',
    cohortMentorshipCtirTitle: 'Тренерство в служении посредством осознанных взаимоотношений',
    // Content Outline
    contentOutlineTitle: 'Краткое содержание',
    contentOutlineParagraph1: 'Программа «Тренерство в служении посредством осознанных взаимоотношений» является фундаментом Института Христианского Лидерства MCNet. Она отражает наши ценности, но разработана в качестве более официальной программы для людей, призванных к лидерству в Церкви. Чтобы достичь этого, кандидаты должны записаться в Институт Христианского Лидерства MCNet. Первые четыре модуля этой программы позволяют получить сертификат христианского лидера и являются обязательным условием для прохождения последних четырех модулей, позволяющих получить сертификат христианского служителя MCNet продвинутого уровня.',
    contentOutlineSubtitle1: 'Сертификат христианского лидера',
    contentOutlineSubparagraph1a: 'Кандидаты на получение сертификата христианского лидера должны быть вовлечены в служение церкви и иметь лидерский опыт, несмотря на отсутствие академических требований. Предполагается, что обучающиеся должны иметь основное общее или среднее общее образование.',
    contentOutlineSubparagraph1b: 'Программа состоит из четырёх модулей. Два модуля изучают лидерские компетенции и два модуля посвящены практическим компетенциям, в общей сложности - 50 КХЛ (Компетенции Христианского Лидерства). Лидерские компетенции: ЛК1.0 Личная жизнь и ЛК 2.0 Взаимоотношения. Практические компетенции: ПК3.0 Основы Библии и ПК4.0 Основы богословия.',
    contentOutlineSubparagraph1c: 'По окончании Программы и в результате её успешного освоения обучаемый овладеет 50 соответствующими навыками и получит Сертификат Христианского Лидера МТС. Сертификат признаётся в Международном Христианском Служении тренеров.',
    contentOutlineSubtitle2: 'Сертификат христианского служителя',
    contentOutlineSubparagraph2a: 'Кандидаты на получение сертификата христианского служителя (продвинутый уровень) должны успешно освоить программу христианского лидерства и иметь сертификат христианского лидера. Программа состоит из четырёх модулей. Два модуля изучают лидерские компетенции и два модуля посвящены практическим компетенциям, в общей сложности - 50 КХС (Компетенции Христианского Служения). Лидерские компетенции: ЛК5.0 Жизнь Церкви и ЛК 6.0 Служения Церкви. Практические компетенции: ПК7.0 Основы Миссии и ПК8.0 Снаряжение других.',
    contentOutlineSubparagraph2b: 'По окончании Программы и в результате её успешного освоения обучаемый овладеет 50 соответствующими навыками и получит Сертификат Христианского Служителя МТС (продвинутый уровень), включающий в себя 100 КХС, 50 из которых составляют обязательные навыки христианского лидерства, требуемыe на начало освоения программы. Сертификаты признаются в Международном Христианском Служении тренеров.',
    contentOutlineLc1: 'ЛК 1.0 Личная жизнь',
    contentOutlineLc1_10: 'ЛК 1.10 Благочестивый характер',
    contentOutlineLc1_20: 'ЛК 1.20 Ключевые ценности',
    contentOutlineLc1_30: 'ЛК 1.30 Духовная дисциплина',
    contentOutlineLc1_40: 'ЛК 1.40 Духовные дары',
    contentOutlineLc1_50: 'ЛК 1.50 Призвание в служении',
    contentOutlineLc1_60: 'ЛК 1.60 Философия лидерства ',
    contentOutlineLc1_70: 'ЛК 1.70 Лидерские способности',
    contentOutlineLc1_80: 'ЛК 1.80 Готовность к непрерывному обучению ',
    contentOutlineLc2: 'ЛК 2.0 Взаимоотношения',
    contentOutlineLc2_10: 'ЛК 2.10 Эмоциональный интеллект',
    contentOutlineLc2_20: 'ЛК 2.20 Командная динамика',
    contentOutlineLc2_30: 'ЛК 2.30 Навыки коммуникации',
    contentOutlineLc2_40: 'ЛК 2.40 Способность к наставничеству',
    contentOutlineLc2_50: 'ЛК 2.50 Здоровые взаимоотношения',
    contentOutlineLc2_60: 'ЛК 2.60 Брак и семья',
    contentOutlineLc2_70: 'ЛК 2.70 Межкультурная и межпоколенческая коммуникация',
    contentOutlineTc3: 'ПК 3.0 Основы Библии',
    contentOutlineTc3_10: 'ПК 3.10 Знание Библии',
    contentOutlineTc3_20: 'ПК 3.20 Толкование Библии',
    contentOutlineTc3_30: 'ПК 3.30 Проповедь Библии',
    contentOutlineTc4: 'ПК 4.0 Основы богословия',
    contentOutlineTc4_10: 'ПК 4.10 История богословия',
    contentOutlineTc4_20: 'ПК 4.20 Основы богословия',
    contentOutlineTc4_30: 'ПК 4.30 Практическое богословие',
    contentOutlineLc5: 'ЛК 5.0 Жизнь Церкви',
    contentOutlineLc5_10: 'ЛК 5.10 Структура Церкви',
    contentOutlineLc5_20: 'ЛК 5.20 Системный анализ',
    contentOutlineLc5_30: 'ЛК 5.30 Модели служений',
    contentOutlineLc5_40: 'ЛК 5.40 Организационные перемены',
    contentOutlineLc5_50: 'ЛК 5.50 Стратегическое планирование',
    contentOutlineLc5_60: 'ЛК 5.60 Управление ресурсами ',
    contentOutlineLc5_70: 'ЛК 5.70 Развитие лидерства',
    contentOutlineLc5_80: 'ЛК 5.80 Передача лидерства)',
    contentOutlineLc6: 'ЛК 6.0 Служения Церкви',
    contentOutlineLc6_10: 'ЛК 6.10 Пятигранные дары',
    contentOutlineLc6_20: 'ЛК 6.20 Пятигранное лидерство',
    contentOutlineLc6_30: 'ЛК 6.30 Пятигранное служение',
    contentOutlineTc7: 'ПК 7.0 Основы миссии',
    contentOutlineTc7_10: 'ПК 7.10 Культурная компетентность',
    contentOutlineTc7_20: 'ПК 7.20 Соединение культур',
    contentOutlineTc7_30: 'ПК 7.30 Умножение',
    contentOutlineTc8: 'ПК 8.0 Снаряжение других',
    contentOutlineTc8_10: 'ПК 8.10 Апостольский потенциал',
    contentOutlineTc8_20: 'ПК 8.20 Пророческий потенциал',
    contentOutlineTc8_30: 'ПК 8.30 Евангелистский потенциал',
    contentOutlineTc8_40: 'ПК 8.40 Пасторский потенциал',
    contentOutlineTc8_50: 'ПК 8.50 Учительский потенциал',
    // International team
    internationalTeamTitle: 'Международная команда',
    // Approved Mentors
    approvedMentorsTitle: 'Квалифицированные наставники',
    approvedMentorsRegionNorthAmerica: 'Северная Америка',
    approvedMentorsRegionLatinAmerica: 'Латинская Америка',
    approvedMentorsRegionEurasia: 'Евразия',
    // Persons
    dougFriesenName: 'Даг Фрейзен',
    dougFriesenBlurb: 'На протяжении всей жизни Даг обучал лидеров в разных форматах: формальное и неформальное обучение, как в церкви, так и учебных заведениях. Будучи основателем церкви и пастором, он всегда уделял особое внимание обучению лидеров, позволяющему им более эффективно служить церкви. Помимо этого, он был посредником в церковных конфликтах, помогал церквям в переходный период и занимался наставничеством нового поколения лидеров. Он является автором ряда учебных материалов по лидерству, применяемых в международном служении. В настоящее время он является президентом канадской организации CLIMB, входящей в международную сеть MCNet. В данный момент он является директором организации «Восхождение» (примечание переводчика - CLIMB – акроним для Christian leaders in Mission Building – христианские лидеры в расширении Миссии), канадское представительство международного тренерского служения. Даг в соавторстве с Филом разработал данную программу обучения.',
    philJeskeName: 'Фил Джеске',
    philJeskeBlurb: 'Фил (д.ф.н.) большую часть жизни был миссионером в Африке и Европе. Он занимается подготовкой лидеров и разработал несколько инновационных учебных программ, используемых по всему миру. Особое внимание Фил уделяет наставничеству следующего поколения, написал несколько книг на эту тему. В настоящее время он является президентом "Международного христианского служения" - миссионерской организации, которая основала " Международное тренерское служение". Занимаясь всю жизнь разработкой инновационных программ обучения лидеров, он вместе с Дагом создал программу, позволяющую объединить жизненный опыт и обучение.',
    carlosPimentalName: 'Карлос Пименталь',
    carlosPimentalBlurb: 'Карлос - опытный лидер, за плечами которого богатая лидерская карьера в различных миссионерских организациях - от гуманитарных служб до учебных организаций по подготовке лидеров. За годы служения он основал несколько различных организаций и является членом совета директоров некоторых из них. В своей работе он уделяет особое внимание развитию команд и подготовке молодых лидеров для раскрытия их максимального потенциала. В настоящее время он работает в Доминиканской Республике, является исполнительным директором миссионерской организации и президентом организации, занимающейся обучением лидеров. Его видение подготовки лидеров охватывает всю Латинскую Америку, и в качестве тренера MCNet он является региональным директором программы в Латинской Америке.',
    raydelRiquelmeName: 'Рэйдел Рикелме',
    raydelRiquelmeBlurb: 'Рэйдел вырос на Кубе, что повлияло на его приоритеты и стремления в служении, которым он сейчас занимается. Находясь на Кубе, он был уважаемым врачом-хирургом, но почувствовал Божье призвание посвятить себя обучению лидеров. В настоящее время он проживает в Доминиканской Республике, что позволяет ему лучше служить лидерам Кубы. Его видение не ограничивается Карибским бассейном и охватывает всю Латинскую Америку. В течение многих лет он работал тренером в MCNet и разработал инновационную программу обучения лидеров, которая используется в Доминиканской Республике и в настоящее время востребована и в других странах этого региона. Он - человек с новаторским мышлением, который постоянно уделяет особое внимание наставничеству в лидерстве через осознанные взаимоотношения.',
    alexiGorbachevName: 'Алексей Горбачев',
    alexiGorbachevBlurb: 'Алексей (к.ф.н.) в настоящее время является директором семинарии в Москве. За последние годы он помог внедрить инновационные изменения в программы семинарии, отвечающие меняющимся потребностям России. Уделяя особое внимание наставничеству нового поколения, он и его команда создали множество онлайн-программ и программ дополнительного обучения, которые отвечают потребностям современного российского лидерства. Он также является пастором и тренером MCNet, обучая лидеров в ряде других стран. Его главная цель - помочь евангельской церкви в России стать востребованной в российском контексте, в связи с чем он участвует в нескольких проектах, таких как Лозаннский Комитет, а также является членом других международных организаций.',
    maxymOliferovskiName: 'Максим Олиферовский',
    maxymOliferovskiBlurb: 'Максим - создатель церкви и директор миссионерской организации на Украине. Он уделяет особое внимание формированию команд и делает это в различных сферах своего служения. В настоящее время он является руководителем команды по основанию церкви, а также осуществляет руководство несколькими другими церквями в своем регионе. Будучи руководителем миссионерской организации, занимающейся служением семьям переселенцев, он служит команде, сталкивающейся с проблемами служения нуждающимся семьям на востоке Украины. Он продолжает предоставлять возможности для обучения лидеров в своей стране и является тренером MCNet в Украине.',
    rafikName: 'Rafik',
    rafikBlurb: 'Рафик родился и вырос в Восточной Африке (Танзания). В юности он пережил глубокое обращение в веру в Иисуса, отказавшись от мусульманского воспитания. Как евангелист он проповедовал по всей Танзании и стал свидетелем основания нескольких церквей, как в африканской, так и в индийской общинах. Его стремление нести Евангелие другим людям сохранилось и после того, как много лет спустя он эмигрировал в Канаду. В настоящее время он работает священником в миссии, которая служит бездомным и людям, страдающим зависимостью. Разнообразный церковный и культурный опыт позволил ему приобрести уникальную практику служения во благо распространения Евангелия.',
    tatianaGorbachevaName: 'Tatiana Горбачева',
    tatianaGorbachevaBlurb: 'Татьяна Горбачева (к.ф.н.) занимала различные ключевые лидерские позиции в своей деноминации и является талантливым преподавателем и специалистом. В качестве тренера в MCNet она руководила многими группами наставничества и была главным вдохновителем, помогая лидерам осознать важность таких отношений. Имея степень по богословию и консультированию, она основала служение семейного консультирования в Москве. Татьяна регулярно путешествует как по России, так и за рубежом, обучая и тренируя лидеров. Кроме того, вместе со своим мужем она осуществляет руководство церковью и семинарией.',
    karlSchneiderName: "Карл Шнайдер",
    karlSchneiderBlurb: "Карл Шнайдер в настоящее время является пастором церкви в восточной Германии и активно участвует в создании новых церквей в своем городе и регионе. В течение многих лет он много путешествовал по миру в качестве евангелиста и учителя. Он очень любит молодое поколение и активно участвует в наставничестве лидеров в своем городе. В качестве тренера MCNet он постоянно делится опытом наставничества молодых лидеров за пределами Германии и Европы.",
    valeraKudaevName: "Валера Кудаев",
    valeraKudaevBlurb: "Валера Кудаев стремится обучать и высвобождать новое поколение лидеров в России. В настоящее время он является пастором церкви, одаренным учителем и тренером по работе с церковными командами. В своей деноминации он подготовил многих лидеров нового поколения, которых они с супругой наставляли на протяжении многих лет. В настоящее время он живет в Санкт-Петербурге и занимается формированием церковной команды, которая служит семьям и продолжает воспитывать новое поколение лидеров. Его богатый практический опыт работы в церкви делает его ценным тренером в служении MCNet.",
    narinaKudaevName: "Нарина Кудаева",
    narinaKudaevBlurb: "Нарина Кудаева - прекрасный лингвист и переводчик, этот дар она активно использует в своем служении. Вместе с мужем она занимается подготовкой лидеров, а также добрачным и семейным консультированием. Она была учителем и директором христианской школой, а в настоящее время работает в Международной школе в Санкт-Петербурге, Россия. Она служит тренером в MCNet, используя свой личный опыт наставничества и высвобождения молодых лидеров в служении.",
  }
});

export default strings;