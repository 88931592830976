import CohortMentorship from './CohortMentorship';
import CompetencyBasedLeadershipTraining from './CompetencyBasedLeadershipTraining';
import ContentOutline from './ContentOutline';
import Montage from './Montage';
import Welcome from './Welcome';
import Links from './Links';
import ProgramTitle from './ProgramTitle';

export default function Home() {
  return (
    <>
      <Montage />
      <Welcome />
      <Links />
      <ProgramTitle />
      <CompetencyBasedLeadershipTraining />
      <CohortMentorship />
      <ContentOutline />
    </>
  );
}