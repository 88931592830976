import strings from '../strings';
import orientationManual_en from '../res/orientation-manual.en.pdf';
import orientationManual_es from '../res/orientation-manual.es.pdf';
import orientationManual_ru from '../res/orientation-manual.ru.pdf';

export default function getOrientationManualHref() {
  switch (strings.getLanguage())
  {
    case 'en':
      return orientationManual_en;
    case 'es':
      return orientationManual_es;
    case 'ru':
      return orientationManual_ru;
    default:
      console.warn(
        `Orientation manual not available in given language: ${strings.getLanguage()}`
      );
      return orientationManual_en;
  }
}