import strings from '../strings';

export default function ProgramTitle() {
  return (
    <div id='program-title'>
      <div id='program-title-anchor'></div>
      <h2>
        {strings.programTitle}
      </h2>
    </div>
  );
}