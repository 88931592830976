/// Describes a supported language code.
type Language = 'en' | 'es' | 'ru';

/// Returns a language for the given string, or the default language
/// if the given string cannot be converted.
function stringToLanguage(str: string) : Language {
  switch (str) {
    case 'en':
    case 'es':
    case 'ru':
      return str;
    default:
      return 'en';
  }
}

export default Language;
export { stringToLanguage };

