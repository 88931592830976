import React from 'react';
import Person, { IPersonProps } from '../shared/Person';
import strings from '../strings';
import imageOfDoug from '../res/doug.jpg';
import imageOfPhil from '../res/phil.jpg';
import imageOfCarlos from '../res/carlos.jpg';
import imageOfRaydel from '../res/raydel.jpg';
import imageOfAlexei from '../res/alexei.jpg';
import imageOfMaxym from '../res/max.jpg';

export default function InternationalTeam() {

  const internationalTeam: IPersonProps[] = [
    {
      imageUrl: imageOfDoug,
      name: strings.dougFriesenName,
      blurb: strings.dougFriesenBlurb,
    },
    {
      imageUrl: imageOfPhil,
      name: strings.philJeskeName,
      blurb: strings.philJeskeBlurb,
    },
    {
      imageUrl: imageOfCarlos,
      name: strings.carlosPimentalName,
      blurb: strings.carlosPimentalBlurb,
    },
    {
      imageUrl: imageOfRaydel,
      name: strings.raydelRiquelmeName,
      blurb: strings.raydelRiquelmeBlurb,
    },
    {
      imageUrl: imageOfAlexei,
      name: strings.alexiGorbachevName,
      blurb: strings.alexiGorbachevBlurb,
    },
    {
      imageUrl: imageOfMaxym,
      name: strings.maxymOliferovskiName,
      blurb: strings.maxymOliferovskiBlurb,
    },
  ];

  return (
    <>
      <h2>{strings.internationalTeamTitle}</h2>
      {internationalTeam.map((person: IPersonProps, index: number) => {
        return (
          <Person {...person} />
        );
      })}
    </>
  );
}
