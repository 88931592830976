import React from 'react'
import { Container } from 'react-bootstrap';
import strings from '../strings';

export default function Welcome() 
{
  return (
    <Container>
      <h2>{strings.welcomeTitle}</h2>
      <p>{strings.welcomeText}</p>
    </Container>
  );
}

