import React, { useCallback, useState } from 'react';

import video from '../res/mcnet-montage.mp4';
import { BrowserView } from 'react-device-detect';
import { HEADER_HEIGHT } from '../shared/Header';

export default function Montage()
{
  const [showControls, setShowControls] = useState<boolean>(false);
  const _handleClick = useCallback(() => setShowControls(true), []);

  return (
    <div style={{ minHeight: 20 }}>
      <BrowserView>
        <video 
          style={{
            width: '100%', 
            height: `calc(100vh - ${HEADER_HEIGHT})`,
            objectPosition: 'center',
            objectFit: 'cover',
          }}
          src={video}
          controls={showControls}
          autoPlay 
          muted
          loop
          onClick={_handleClick}
          />
      </BrowserView>
    </div>
  );
}