import React, { useEffect, useState } from 'react';
import { 
  Header, 
  Footer
}  from './shared';
import Home from './Home';
import InternationalTeam from './InternationalTeam';
import ApprovedMentors from './ApprovedMentors';
import strings from './strings';
import { Route, Routes }from 'react-router-dom';
import Http404Page from './shared/Http404Page';
import Language, { stringToLanguage } from './Language';

function App() {

  const [language, _setLanguage] = useState<Language>(stringToLanguage(strings.getInterfaceLanguage()));
  const setLanguage = (language: Language): void => {
    strings.setLanguage(language);
    _setLanguage(language);
  };

  useEffect(() => {
    document.title = strings.orgShortName;
  }, [language]);

  return (
    <>
      <Header 
        language={language} 
        setLanguage={setLanguage} 
      />
      <div id="content-container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/international-team" element={<InternationalTeam />} />
          <Route path="/approved-mentors" element={<ApprovedMentors />} />
          <Route path="*" element={<Http404Page />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
