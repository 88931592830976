
import { Container, Row, Col } from 'react-bootstrap';
import { BodyText, BoxArrowUpRight, Book } from 'react-bootstrap-icons';
import { HashLink } from 'react-router-hash-link';
import strings from '../strings';
import getOrientationManualHref from './OrientationManual';

const ICON_SIZE : number = 64;

export default function Links() {

  return (
    <>
      <section className="links">
        <Container>
          <Row>
            <Col md={4} >
              <HashLink className="link-icon" to="/#program-title-anchor">
                <BodyText size={ICON_SIZE} />
                <span className="h4">{strings.linkSeeTheProgram}</span>
              </HashLink>
            </Col>
            <Col md={4}>
              <a className="link-icon" href={getOrientationManualHref()}>
                <Book size={ICON_SIZE} />
                <span className="h4">{strings.linkSeeTheOrientationManual}</span>
              </a>
            </Col>
            <Col md={4} >
              <HashLink className="link-icon" to="/international-team#">
                <BoxArrowUpRight size={ICON_SIZE} />
                <span className="h4">{strings.linkSeeTheTeam}</span>
              </HashLink>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )

}