import { Container } from 'react-bootstrap';
import strings from '../strings';
import getOrientationManualHref from './OrientationManual';

export default function CohortMentorship()
{
  return (
    <section>
      <Container>
        <h3>{strings.cohortMentorshipTitle}</h3>
        <p>{strings.formatString(
          strings.cohortMentorshipParagraph1,
          <a
            href={getOrientationManualHref()}
            target="_blank"
            rel="noreferrer">
            {strings.cohortMentorshipOrientationManual}
          </a>
        )}</p>
        <p>{strings.cohortMentorshipParagraph2}</p>
        <p>{strings.cohortMentorshipParagraph3}</p>
        <p>{strings.cohortMentorshipParagraph4}</p>
        <p>{strings.cohortMentorshipParagraph5}</p>
        <h4>{strings.cohortMentorshipSubtitle1}</h4>
        <p>{strings.cohortMentorshipSubparagraph1}</p>
        <h4>{strings.cohortMentorshipSubtitle2}</h4>
        <p>{strings.cohortMentorshipSubparagraph2a}</p>
        <p>{strings.cohortMentorshipSubparagraph2b}</p>
        <p>{strings.cohortMentorshipSubparagraph2c}</p>
        <h4>{strings.cohortMentorshipSubtitle3}</h4>
        <p>{strings.cohortMentorshipSubparagraph3a}</p>
        <p>{strings.formatString(
          strings.cohortMentorshipSubparagraph3b,
          <i>{strings.cohortMentorshipCtirTitle}</i>
        )}</p>
      </Container>
    </section>
  );
}