import React from 'react';
import Person, { IPersonProps } from '../shared/Person';
import strings from '../strings';
import imageOfDoug from '../res/doug.jpg';
import imageOfPhil from '../res/phil.jpg';
import imageOfRafik from '../res/rafik.jpg';
import imageOfCarlos from '../res/carlos.jpg';
import imageOfRaydel from '../res/raydel.jpg';
import imageOfAlexei from '../res/alexei.jpg';
import imageOfMaxym from '../res/max.jpg';
import imageOfTatiana from '../res/tatiana.jpg';
import imageOfKarl from '../res/karl.jpg';
import imageOfValera from '../res/valera.jpg';
import imageOfNarina from '../res/narina.jpg';
import { Container } from 'react-bootstrap';

interface IRegion {
  title: string;
  persons: IPersonProps[];
}

export default function ApprovedMentors() {

  const regions : IRegion[] = [
    {
      title: strings.approvedMentorsRegionNorthAmerica,
      persons: [
        {
          imageUrl: imageOfDoug,
          name: strings.dougFriesenName,
          blurb: strings.dougFriesenBlurb,
        },
        {
          imageUrl: imageOfPhil,
          name: strings.philJeskeName,
          blurb: strings.philJeskeBlurb,
        },
        {
          imageUrl: imageOfRafik,
          name: strings.rafikName,
          blurb: strings.rafikBlurb,
        },
      ]
    },
    {
      title: strings.approvedMentorsRegionLatinAmerica,
      persons: [
        {
          imageUrl: imageOfCarlos,
          name: strings.carlosPimentalName,
          blurb: strings.carlosPimentalBlurb,
        },
        {
          imageUrl: imageOfRaydel,
          name: strings.raydelRiquelmeName,
          blurb: strings.raydelRiquelmeBlurb,
        },
      ]
    },
    {
      title: strings.approvedMentorsRegionEurasia,
      persons: [
        {
          imageUrl: imageOfAlexei,
          name: strings.alexiGorbachevName,
          blurb: strings.alexiGorbachevBlurb,
        },
        {
          imageUrl: imageOfTatiana,
          name: strings.tatianaGorbachevaName,
          blurb: strings.tatianaGorbachevaBlurb,
        },
        {
          imageUrl: imageOfKarl,
          name: strings.karlSchneiderName,
          blurb: strings.karlSchneiderBlurb,
        },
        {
          imageUrl: imageOfMaxym,
          name: strings.maxymOliferovskiName,
          blurb: strings.maxymOliferovskiBlurb,
        },
        {
          imageUrl: imageOfValera,
          name: strings.valeraKudaevName,
          blurb: strings.valeraKudaevBlurb,
        },
        {
          imageUrl: imageOfNarina,
          name: strings.narinaKudaevName,
          blurb: strings.narinaKudaevBlurb,
        },
      ]
    },
  ];

  return (
    <div className="approved-mentors">
      <h2>{strings.approvedMentorsTitle}</h2>
      {regions.map((region: IRegion) => {
        return (
          <>
            <Container>
              <h3>{region.title}</h3>
            </Container>
            {region.persons.map((person: IPersonProps) => {
              return (
                <Person
                  {...person}
                />
              );
            })}
          </>
        );
      })}
    </div>
  );
}