import React from 'react';
import { Container } from 'react-bootstrap';
import strings from '../strings';

export default function CohortMentorship()
{
  return (
    <section className="bg-mid">
      <Container>
        <h3>{strings.contentOutlineTitle}</h3>
        <p>{strings.contentOutlineParagraph1}</p>
        <h4>{strings.contentOutlineSubtitle1}</h4>
        <p>{strings.contentOutlineSubparagraph1a}</p>
        <p>{strings.contentOutlineSubparagraph1b}</p>
        <ul>
          <li>
            {strings.contentOutlineLc1}
            <ul>
              <li>{strings.contentOutlineLc1_10}</li>
              <li>{strings.contentOutlineLc1_20}</li>
              <li>{strings.contentOutlineLc1_30}</li>
              <li>{strings.contentOutlineLc1_40}</li>
              <li>{strings.contentOutlineLc1_50}</li>
              <li>{strings.contentOutlineLc1_60}</li>
              <li>{strings.contentOutlineLc1_70}</li>
              <li>{strings.contentOutlineLc1_80}</li>
            </ul>
          </li>
          <li>
            {strings.contentOutlineLc2}
            <ul>
              <li>{strings.contentOutlineLc2_10}</li>
              <li>{strings.contentOutlineLc2_20}</li>
              <li>{strings.contentOutlineLc2_30}</li>
              <li>{strings.contentOutlineLc2_40}</li>
              <li>{strings.contentOutlineLc2_50}</li>
              <li>{strings.contentOutlineLc2_60}</li>
              <li>{strings.contentOutlineLc2_70}</li>
            </ul>
          </li>
          <li>
            {strings.contentOutlineTc3}
            <ul>
              <li>{strings.contentOutlineTc3_10}</li>
              <li>{strings.contentOutlineTc3_20}</li>
              <li>{strings.contentOutlineTc3_30}</li>
            </ul>
          </li>
          <li>
            {strings.contentOutlineTc4}
            <ul>
              <li>{strings.contentOutlineTc4_10}</li>
              <li>{strings.contentOutlineTc4_20}</li>
              <li>{strings.contentOutlineTc4_30}</li>
            </ul>
          </li>
        </ul>
        <p>{strings.contentOutlineSubparagraph1c}</p>
        <h4>{strings.contentOutlineSubtitle2}</h4>
        <p>{strings.contentOutlineSubparagraph2a}</p>
        <ul>
          <li>
            {strings.contentOutlineLc5}
            <ul>
              <li>{strings.contentOutlineLc5_10}</li>
              <li>{strings.contentOutlineLc5_20}</li>
              <li>{strings.contentOutlineLc5_30}</li>
              <li>{strings.contentOutlineLc5_40}</li>
              <li>{strings.contentOutlineLc5_50}</li>
              <li>{strings.contentOutlineLc5_60}</li>
              <li>{strings.contentOutlineLc5_70}</li>
              <li>{strings.contentOutlineLc5_80}</li>
            </ul>
          </li>
          <li>
            {strings.contentOutlineLc6}
            <ul>
              <li>{strings.contentOutlineLc6_10}</li>
              <li>{strings.contentOutlineLc6_20}</li>
              <li>{strings.contentOutlineLc6_30}</li>
            </ul>
          </li>
          <li>
            {strings.contentOutlineTc7}
            <ul>
              <li>{strings.contentOutlineTc7_10}</li>
              <li>{strings.contentOutlineTc7_20}</li>
              <li>{strings.contentOutlineTc7_30}</li>
            </ul>
          </li>
          <li>
            {strings.contentOutlineTc8}
            <ul>
              <li>{strings.contentOutlineTc8_10}</li>
              <li>{strings.contentOutlineTc8_20}</li>
              <li>{strings.contentOutlineTc8_30}</li>
              <li>{strings.contentOutlineTc8_40}</li>
              <li>{strings.contentOutlineTc8_50}</li>
            </ul>
          </li>
        </ul>
        <p>{strings.contentOutlineSubparagraph2b}</p>
      </Container>
    </section>
  );
}