import { Container } from 'react-bootstrap';
import strings from '../strings';

export default function CompetencyBasedLeadershipTraining()
{
  return (
    <section className="bg-mid">
      <Container>
        <h3>{strings.competencyTitle}</h3>
        <p>{strings.competencyParagraph1}</p>
        <p>{strings.competencyParagraph2}</p>
        <p>{strings.competencyParagraph3}</p>
        <h4>{strings.competencySubtitle1}</h4>
        <p>{strings.competencySubparagraph1}</p>
        <h4>{strings.competencySubtitle2}</h4>
        <p>{strings.competencySubparagraph2}</p>
        <h4>{strings.competencySubtitle3}</h4>
        <p>{strings.competencySubparagraph3}</p>
        <h4>{strings.competencySubtitle4}</h4>
        <p>{strings.competencySubparagraph4}</p>
      </Container>
    </section>
  );
}