import strings from '../strings';

import '../common.scss';

export default function Footer() 
{
  return (
    <footer>
      {strings.formatString(
        strings.footerCopyRight,
        new Date().getFullYear()
      )}
    </footer>
  );
}